import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import LineItemHeader from './LineItemHeader';
import LineItemImageViewer from './LineItemImageViewer';
import LineItemColorChanges from './LineItemColorChanges';
import LineItemSizes from './LineItemSizes';
import LineItemTotalBreakdown from './LineItemTotalBreakdown';
import LineItemPromoInformation from './LineItemPromoInformation';
import LineItemUnitPriceEditor from './LineItemUnitPriceEditor';
import LineItemArtReview from './LineItemArtReview';
import ReceivableFields from './ReceivableFields';
import DragHandle from './DragHandle';
import ActionMenu from './ActionMenu';
import Icon from './Icon';
import classNames from 'classnames';
import Notification from './notification';
import { formatMoney } from '../utility';
import { localPatchJSON, extractErrorMessage } from '../fetch-local';

export default class LineItem extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  // This was brought over from the LineItemUnitPriceEditor component
  // so it probably needs cleaned up a bit, but it is working.

  setPrice() {
    const { props } = this;

    props.onPricingUpdate({ ...props, isLoading: true });

    localPatchJSON(props.links.setPrice, {
      line_item: {
        unit_price: props.data.unitPrice,
        price_overridden: props.data.isPriceOverridden,
      },
    })
      .then(data => {
        props.onPricingUpdate({ ...data.lineItem, isLoaded: true }, data.order);
        if (props.data.canCheckout !== data.order.canCheckout) {
          window.location.reload();
        }
      })
      .catch(error => {
        extractErrorMessage(error, 'There was an issue').then(error => {
          props.onPricingUpdate({ ...props, error });
        });
      });
  }

  handleClick = () => {
    this.setPrice();
  };

  render() {
    const user = this.props.meta.currentUser;
    const canViewOutOfStock = this.props.meta.currentUser.canViewOutOfStock;
    const { isLoaded } = this.props;

    const outOfStockWarning = this.props.data.outOfStock ? (
      <p>
        <strong className="txt-alert">Warning! </strong>
        {this.props.data.outOfStockWarning}
        <br />
        {canViewOutOfStock && (
          <a href={`/stock_alerts`}>View Out of Stock Items</a>
        )}
      </p>
    ) : null;

    const outOfStockSizesWarning = this.props.data.outOfStockSizesWarning ? (
      <p>
        <strong className="txt-alert">Warning! </strong>
        {this.props.data.outOfStockSizesWarning}
        <br />
        {canViewOutOfStock && (
          <a href={`/stock_alerts`}>View Out of Stock Items</a>
        )}
      </p>
    ) : null;

    const priceAdjustedWarning = this.props.data.priceAdjusted ? (
      <Notification type="alert">
        <p className="mtn">
          <strong>Warning! </strong>
          To meet minimum pricing requirements, the unit price of this item has
          been automatically adjusted from{' '}
          <strong>{formatMoney(this.props.data.quotedPrice)}</strong> to{' '}
          <strong>{formatMoney(this.props.data.unitPrice)}</strong>. If your
          most recent change caused this warning, you can undo that change to
          restore the original price. If you wish to accept the new price, use
          the button below.
        </p>
        <button className="button button--alert" onClick={this.handleClick}>
          Keep Adjusted Price
        </button>
      </Notification>
    ) : null;

    const viewBirdBankButton = this.props.data.birdBankId ? (
      <ActionMenu dropdownRight>
        <a href={`/bird_banks/${this.props.data.birdBankId}`}>
          <Icon type="arrow-circle-right" color="inherit" /> View Bird Bank
        </a>
      </ActionMenu>
    ) : null;

    const viewArtReview = user.canViewAffinity ? (
      <LineItemArtReview {...this.props} />
    ) : null;

    const testAttrs = isLoaded ? { 'data-test-loaded': true } : {};

    return (
      <Draggable
        draggableId={this.props.id}
        index={this.props.index}
        isDragDisabled={!this.props.draggable}
      >
        {provided => (
          <div
            className={classNames({
              'line-item': true,
              'out-of-stock': this.props.data.outOfStock,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...testAttrs}
          >
            {this.props.draggable && (
              <DragHandle {...provided.dragHandleProps} />
            )}

            <div className="line-item__detail">
              <div className="flex-rows flex-rows--spaced-b flex-gap-x-sm">
                <LineItemHeader {...this.props} />
                {viewBirdBankButton}
              </div>
              {priceAdjustedWarning}
              <div className="line-item__body">
                <div className="line-item-image-viewer line-item-image-viewer--bordered">
                  <LineItemImageViewer images={this.props.data.images} />
                </div>

                <LineItemColorChanges {...this.props} />

                <LineItemSizes {...this.props} />

                {this.props.meta.currentUser.canViewPricingSection && (
                  <div>
                    <LineItemUnitPriceEditor
                      {...this.props}
                      setPrice={this.setPrice}
                    />

                    <LineItemTotalBreakdown {...this.props} />
                  </div>
                )}

                {viewArtReview}

                {this.props.meta.currentUser.canViewPricingSection && (
                  <LineItemPromoInformation {...this.props} />
                )}

                <ReceivableFields {...this.props} />
              </div>
              {outOfStockWarning}
              {outOfStockSizesWarning}
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}
