// jQuery jumps and hoops
import '../polyfills';
import '../airbrake';
import $ from 'jquery';
import Rails from 'rails-ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'whatwg-fetch';
import Promise from 'promise-polyfill';
import 'nodelist-foreach-polyfill';
import init from 'progressive-enhancement';
import DomFiddling from 'dom-fiddling';

import Select from 'react-select';
import 'pickadate/lib/picker';
import 'pickadate/lib/picker.date';
import { setupDateInputs } from '../date-inputs';
import setupDatePollyfill from '../polyfills/date-input';

import CommentModel from '../stores/comments';
import RevisionsModel from '../stores/revisions';

import '../stores/global-data';

import '../../../vendor/assets/javascripts/modernizr-custom';

import '../item-proof-popup';
import '../art-revision-popup';
import '../bird_bank';
import '../comments';
import '../update_line_item_count';
import '../file_reader';

import '../stores/locations';
// import 'country_state_select';
import '../mediacheck';
import '../dropdown';

import '../filter_form';

import '../remote_form';
import '../bulk_payment_form';
import '../enabled_by';
import '../bind_value_to';
import '../fill_in_all';
import '../floatLabel';
import '../logo';
import '../pickUpAddressToggle';
import Reconciler from '../reconciler';
import '../bird_bank_shipping_toggle';
import '../reveal';
import '../select_all';
import '../select_price';
import '../tabs';
import '../validate_payment_form';
import '../select_payment_method';
import '../select_customer';
import setupFieldsetHiding from '../fieldset_hide';
import setupSortableTable from '../sortable_table';
import '../customer-address-toggle';
import 'moment/moment';

import '../filter_select_menus';
import '../toggle_order_sidebar';

import '../dashboard';
// import 'modernizr-custom';
import '../archived_orders';
import '../product';
import 'trix';
import '@rails/actiontext';

window.jQuery = $;
window.$ = $;

require('../card_carousel');
require('../fancy_box');

require('slick-carousel');
require('@fancyapps/fancybox');
require('sweetalert/dist/sweetalert-dev');

// UJS
Rails.start();
ActiveStorage.start();

window.Select = Select;

// Pick-a-date stuff...
setupDateInputs();
setupDatePollyfill();

$(setupFieldsetHiding);
$(setupSortableTable);
$(Reconciler);

// TODO: can we delete this as this is now loaded as an ES6 module?
window.CommentModel = CommentModel;

window.RevisionsModel = RevisionsModel;

// Polyfill promise is it isn't defined
if (!window.Promise) {
  window.Promise = Promise;
}

if (!window.GlobalData) window.GlobalData = {};

// // Run progressive enhacement scripts
init();
DomFiddling();

// Import images
const importAll = r => r.keys().map(r);
importAll(require.context('../images', true, /\.(png|jpe?g|svg)$/));

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.mountComponents();
