import { AttachmentUpdate, AttachmentUpdateType } from './attachment-types';

declare global {
  interface Window {
    OrderApp: {
      environment: 'test' | 'development' | 'staging' | 'production';

      smartyApiKey: string;

      tenantConfig: {
        name: string;
        shortName: string;
        isCollegiate: boolean;
        isBusiness: boolean;
        allowFreeItems: boolean;
        collectGreekRoyalties: boolean;
      };

      airbrakeConfig: {
        projectId: string;
        projectKey: string;
        environment: string;
      };
    };
  }
}

export enum Permissions {
  ReadOnly,
  ReadWrite,
}

export enum NotificationType {
  Success = 'success',
  Alert = 'alert',
  Warning = 'warning',
  Notice = 'notice',
}

export interface Notifications {
  messages: string[];
  type: NotificationType;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface School {
  id: Id;
  name: string;
}

export interface Organization {
  id: Id;
  name: string;
}

export enum ArtTier {
  NotSet,
  Original,
  Signature,
  Creative,
}

export enum DecorationStyle {
  Normal = 0,
  Vintage = 101,
  PuffPrint = 102,
  SimulatedProcess = 103,
  OneHit = 104,
  VintageWithUnderbase = 105,
  PhotoshopSeparation = 106,
  PuffEmbroidery = 201,
}

export enum DecorationType {
  ScreenPrint,
  Embroidery,
  HeatPress,
  TackleTwill,
  PickAPocket,
  Flocking,
  Promo,
  CustomTag,
}

export type Id = number;
export type Error = string;

type AttachedImage = string;

export type MaybeString = string | null;

export interface Attachment {
  id: Id;
  url: string;
  filename: string;
  contentType: string;
  previewUrls: {
    thumbnail: MaybeString; // TODO: make these not-optional??
    fullsize: MaybeString;
  };
}

export interface AttachmentValidation {
  maxSize: number;
  allowedContentTypes: string[];
}

export interface User {
  id: Id;
  firstName: string;
  lastName: string;
}

export interface Post {
  id: Id;
  message: string;
  isEdited: boolean;
  createdAt: Date;
  updatedAt: Date;
  user: User;
  attachments: Attachment[];
  links: {
    self: string;
  };
}

export interface Task {
  id: Id;
  description: string;
  isEdited: boolean;
  completed: boolean;
  viewed: boolean;
  createdAt: Date;
  updatedAt: Date;
  user: User;
  attachments: Attachment[];
  links: {
    self: string;
  };
}

export interface Revision {
  id: Id;
  completed: boolean;
  description: string;
  attachment: Attachment | null;
  userName: string;
  itemProofOnly: boolean;
}

export interface UpdatedRevision extends Revision {
  attachmentUpdate: AttachmentUpdate;
}

export interface UpdatedLocation extends Location {
  proofStatus: AttachmentUpdateType;
  proofUpdate: AttachmentUpdate;
  threadCount: string;
}

export interface UpdatedLineItem extends LineItem {
  primaryImageUpdate: AttachmentUpdate;
  secondaryImageUpdate: AttachmentUpdate;
  compositePrimaryStatus: AttachmentUpdateType;
  compositeSecondaryStatus: AttachmentUpdateType;
}

export interface NewRevision {
  completed: boolean;
  description: string;
  attachment: MaybeString;
  locationId: Id;
  userName: string;
}

export interface DecorationColor {
  id: Id;
  name: string;
  pms: string;
  hex?: string;
  decorationType: DecorationType;
}

export interface Order {
  id: Id;
  jobNumber: string;
  name: string;
}

interface TackleTwillLayerOptions {
  fabricName: string;
  threadName: string;
  stitchType: string;
}

export interface TackleTwillOptions {
  kind: 'tackle-twill';
  numberOfLetters: number;
  numberOfLayers: number;
  foreground: TackleTwillLayerOptions;
  background?: TackleTwillLayerOptions;
}

export interface HeatPressOptions {
  kind: 'heat-press';
  type: string;
  letteringStyle: string;
}

export interface EmbroideryOptions {
  kind: 'embroidery';
  threadCount: number;
}

export interface FlockingOptions {
  kind: 'flocking';
  size: string;
  flockColors: DecorationColor[];
  vinylColors: DecorationColor[];
}

export type DecorationOptions =
  | TackleTwillOptions
  | HeatPressOptions
  | EmbroideryOptions
  | FlockingOptions;

export interface Location {
  id: Id;

  location: string | null;

  decorationOptions?: DecorationOptions;

  proof: Attachment | null;
  description: string | null;
  productionNotes: string | null;
  revisions: Revision[];
  decorationStyle: DecorationStyle;
  colors: DecorationColor[];
  decorationType: DecorationType;
  artFiles: Attachment[];
  attachment?: MaybeString;
  dimensions?: Dimensions;
  pendingItemProofs?: boolean;

  availableDecorationStyles: DecorationStyle[];

  links: {
    self: string;
  };
}

export interface Product {
  id: Id;
  style: string;
  name?: string;
}

export interface Brand {
  id: Id;
  name: string;
}

export interface LineItem {
  id: Id;
  description: string;
  color: string;
  compositePrimary: Attachment | null;
  compositeSecondary: Attachment | null;
  links: {
    self: string;
  };
}

export interface ArtJob {
  id: Id;
  greek: boolean;
  collegiate: boolean;
  collegeMarks: boolean;

  uteesOriginalArt: boolean;
  artCode: string | null;
  printColorChange: boolean;

  allocatorNotes: string;
  artTier: ArtTier;

  school?: School;
  organization?: Organization;

  links: {
    self: string;
    artSubmission: string;
    artJob: string;
  };

  currentState: {
    state: string;
    class: string;
  };
}
